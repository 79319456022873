import { useMemo } from "react"
import { $createCodeNode } from "@lexical/code"
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from "@lexical/list"
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text"
import { $setBlocksType } from "@lexical/selection"
import { $createParagraphNode, $getSelection, $isRangeSelection } from "lexical"

import {
  CodeLightIcon,
  FontCaseLightIcon,
  H1LightIcon,
  H2LightIcon,
  H3LightIcon,
  H4LightIcon,
  ListOlLightIcon,
  ListUlLightIcon,
  MessageQuoteLightIcon,
} from "@ninjaone/icons"
import { localizationKey, localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../../../Text"

import { Dropdown, DROPDOWN_ICON_COLOR, DROPDOWN_TEXT_COLOR, DropdownItem } from "../../../Components"

import { getBlockTypeOptions } from "../utils"
import { StyledToolbarItem } from "../styled"

export const BlockFormatSection = ({ dropdownClassName, editor, blockType, rootType, disabled = false }) => {
  const blockTypeOptions = useMemo(() => getBlockTypeOptions(), [])

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = headingSize => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createHeadingNode(headingSize))
      })
    }
  }

  const formatBulletList = () => {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      formatParagraph()
    }
  }

  const formatNumberedList = () => {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      formatParagraph()
    }
  }

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createQuoteNode())
      })
    }
  }

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        let selection = $getSelection()

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode())
          } else {
            const textContent = selection.getTextContent()
            const codeNode = $createCodeNode()
            selection.insertNodes([codeNode])
            selection = $getSelection()
            if ($isRangeSelection(selection)) selection.insertRawText(textContent)
          }
        }
      })
    }
  }

  return (
    <Dropdown
      dropdownClassName={dropdownClassName}
      disabled={disabled}
      ButtonComponent={StyledToolbarItem}
      ButtonIcon={blockTypeOptions[blockType].Icon}
      buttonAriaLabel={localized("Formatting options for text style")}
    >
      {/* TODO: Use object from blockTypeOptions */}
      <DropdownItem onClick={formatParagraph}>
        <FontCaseLightIcon color={DROPDOWN_ICON_COLOR} />
        <Text color={DROPDOWN_TEXT_COLOR} token={localizationKey("Paragraph")} size="sm" />
      </DropdownItem>
      <DropdownItem onClick={() => formatHeading("h1")}>
        <H1LightIcon color={DROPDOWN_ICON_COLOR} />
        <Text color={DROPDOWN_TEXT_COLOR} token={localizationKey("Header 1")} size="sm" />
      </DropdownItem>
      <DropdownItem onClick={() => formatHeading("h2")}>
        <H2LightIcon color={DROPDOWN_ICON_COLOR} />
        <Text color={DROPDOWN_TEXT_COLOR} token={localizationKey("Header 2")} size="sm" />
      </DropdownItem>
      <DropdownItem onClick={() => formatHeading("h3")}>
        <H3LightIcon color={DROPDOWN_ICON_COLOR} />
        <Text color={DROPDOWN_TEXT_COLOR} token={localizationKey("Header 3")} size="sm" />
      </DropdownItem>
      <DropdownItem onClick={() => formatHeading("h4")}>
        <H4LightIcon color={DROPDOWN_ICON_COLOR} />
        <Text color={DROPDOWN_TEXT_COLOR} token={localizationKey("Header 4")} size="sm" />
      </DropdownItem>
      <DropdownItem onClick={formatBulletList}>
        <ListUlLightIcon color={DROPDOWN_ICON_COLOR} />
        <Text color={DROPDOWN_TEXT_COLOR} token={localizationKey("Bullet List")} size="sm" />
      </DropdownItem>
      <DropdownItem onClick={formatNumberedList}>
        <ListOlLightIcon color={DROPDOWN_ICON_COLOR} />
        <Text color={DROPDOWN_TEXT_COLOR} token={localizationKey("Numbered List")} size="sm" />
      </DropdownItem>
      <DropdownItem onClick={formatQuote}>
        <MessageQuoteLightIcon color={DROPDOWN_ICON_COLOR} />
        <Text color={DROPDOWN_TEXT_COLOR} token={localizationKey("Quote")} size="sm" />
      </DropdownItem>
      <DropdownItem onClick={formatCode}>
        <CodeLightIcon color={DROPDOWN_ICON_COLOR} />
        <Text color={DROPDOWN_TEXT_COLOR} token={localizationKey("Code Block")} size="sm" />
      </DropdownItem>
    </Dropdown>
  )
}
