import { useCallback } from "react"
import { uniq } from "ramda"
import { $createCodeNode, $isCodeNode } from "@lexical/code"
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $createTextNode, $getRoot, $insertNodes } from "lexical"

import { FileCodeLightIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { localizationKey, localized, localizedWith } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../../../Text"

import {
  DROPDOWN_ICON_COLOR,
  DROPDOWN_ICON_DISABLED_COLOR,
  DROPDOWN_TEXT_COLOR,
  DROPDOWN_TEXT_DISABLED_COLOR,
} from "../../../Components"
import { SET_IS_EDITING_SOURCE_COMMAND } from "../../SourcePlugin"
import { CLEAR_MESSAGE_COMMAND, SET_MESSAGE_COMMAND } from "../../MessagePlugin"

import { StyledToolbarItem } from "../styled"

const PRETTIER_PARSER_MODULES = {
  html: () => import("prettier/parser-html"),
}

const PRETTIER_OPTIONS = {
  html: {
    parser: "html",
    printWidth: 120,
  },
}

async function loadPrettierParser(lang) {
  const dynamicImport = PRETTIER_PARSER_MODULES[lang]
  return dynamicImport()
}

async function loadPrettierFormat() {
  const { format } = await import("prettier/standalone")
  return format
}

function getPrettierOptions(lang) {
  const options = PRETTIER_OPTIONS[lang]
  if (!options) throw new Error(`ToolbarPlugin: Language ${lang} is not supported.`)

  return options
}

const SUPPORTED_HTML_ELEMENTS = [
  "p",
  "span",
  "b",
  "strong",
  "i",
  "em",
  "u",
  "h1",
  "h2",
  "h3",
  "h4",
  "ul",
  "li",
  "ol",
  "br",
  "blockquote",
  "pre",
  "a",
  "sub",
  "sup",
  "code",
  "s",
  "table",
  "col",
  "tbody",
  "tr",
  "td",
  "img",
  "hr",
  "colgroup",
  "div",
  "th",
]
const SUPPORTED_HTML_REGEX = /<([a-z]+[1-6]?)(?=\s|>)/gi

// TODO: Handle when prettier fails
export const SourceSection = ({ isEditable, isEditingSource, setIsEditingSource }) => {
  const [editor] = useLexicalComposerContext()

  const handleToggle = useCallback(async () => {
    const format = await loadPrettierFormat()

    const options = getPrettierOptions("html")
    options.plugins = [await loadPrettierParser("html")]

    editor.update(() => {
      const root = $getRoot()
      const firstChild = root.getFirstChild()

      if ($isCodeNode(firstChild) && firstChild.getLanguage() === "html") {
        const parser = new DOMParser()
        const dom = parser.parseFromString(root.getTextContent(), "text/html")

        const matches = []
        let match
        while ((match = SUPPORTED_HTML_REGEX.exec(root.getTextContent()))) {
          if (!SUPPORTED_HTML_ELEMENTS.includes(match[1].toLowerCase())) {
            matches.push(match[1])
          }
        }

        if (matches.length) {
          editor.dispatchCommand(SET_MESSAGE_COMMAND, {
            titleToken: localizationKey("Unsupported HTML elements"),
            variant: "warning",
            children: localizedWith(
              "This content contains the following unsupported HTML elements: <%elements>elements<%>",
              {
                elements: () => (
                  <Text as="span" size="sm" italic fontWeight={tokens.typography.fontWeight.semiBold}>
                    {uniq(matches).join(", ")}
                  </Text>
                ),
              },
            ),
          })
        } else {
          setIsEditingSource(false)
          editor.dispatchCommand(CLEAR_MESSAGE_COMMAND)
          editor.dispatchCommand(SET_IS_EDITING_SOURCE_COMMAND, false)
          const nodes = $generateNodesFromDOM(editor, dom)
          root.clear()
          $insertNodes(nodes)
        }
      } else {
        setIsEditingSource(true)
        editor.dispatchCommand(SET_IS_EDITING_SOURCE_COMMAND, true)
        const html = $generateHtmlFromNodes(editor)
        const prettyHtml = format(html, options)
        root.clear().append($createCodeNode("html").append($createTextNode(prettyHtml)))
      }

      root.selectEnd()
    })
  }, [editor, setIsEditingSource])

  return (
    <StyledToolbarItem
      disabled={!isEditable}
      onClick={handleToggle}
      type="button"
      title={localized("Source")}
      aria-label={localized("Source")}
      isActive={isEditingSource}
    >
      <FileCodeLightIcon color={isEditable ? DROPDOWN_ICON_COLOR : DROPDOWN_ICON_DISABLED_COLOR} />
      <Text
        size="sm"
        color={isEditable ? DROPDOWN_TEXT_COLOR : DROPDOWN_TEXT_DISABLED_COLOR}
        token={localizationKey("Source")}
      />
    </StyledToolbarItem>
  )
}
